import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import '@quasar/extras/material-icons/material-icons.css';
import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';import Meta from 'quasar/src/plugins/meta/Meta.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';import SessionStorage from 'quasar/src/plugins/storage/SessionStorage.js';
import lang from 'quasar/lang/ja.js'
import './styles/quasar.scss'

// To be used on app.use(Quasar, { ... })
export default {
  config: {},

  components: {
    QCheckbox,
    QRouteTab,
  },

  plugins: {
    Dialog,
    Loading,
    LocalStorage,
    Meta,
    Notify,
    SessionStorage,
  },

  lang: lang,
}
